import { modalParams } from "../base/settings";

import LazyLoad from "vanilla-lazyload";
import AOS from 'aos';
import MicroModal from 'micromodal';

import 'aos/dist/aos.css';

export const lazyImageController = new LazyLoad({
  elements_selector: ".lazy__item",

  callback_loaded: (trigger) => {
    const container = trigger.closest(".lazy");
    container.classList.remove('lazy--preloader');
  },
});

export const lazyBackgroundController = new LazyLoad({
  elements_selector: '.lazy-bg',
});

AOS.init({
  once: true,
});

MicroModal.init(modalParams);
