import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

new Swiper('#product-section .swiper', {
  modules: [Navigation],

  speed: 500,
  slidesPerView: 1.4,
  spaceBetween: 24,

  navigation: {
    prevEl: "#product-section .swiper-button-prev",
    nextEl: "#product-section .swiper-button-next",
  },

  breakpoints: {
    540: {
      slidesPerView: 2,
    },

    800: {
      slidesPerView: 3,
      spaceBetween: 32,
    },

    1400: {
      slidesPerView: 4,
      spaceBetween: 45,
    }
  }
});