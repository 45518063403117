import { CountUp } from 'countup.js';

const digits = document.querySelectorAll('.advantages-block__achievement > div');
digits.forEach(digit => {
  new CountUp(digit, digit.dataset.value,
    {
      enableScrollSpy: true,
      scrollSpyOnce: true,
      separator: ' ',
      duration: 3,
      suffix: ` ${digit.dataset.suffix}`
    })
    .start();
});

