import { Collapse } from '../utilities/collapse';

const mobileDropdown = document.querySelectorAll('.dropdown-mobile');
mobileDropdown.forEach(dropdown => {
  const header = dropdown.querySelector('div');
  const body = dropdown.querySelector('ul');

  const collapse = new Collapse(body, 450);

  header.addEventListener('click', () => {
    dropdown.classList.toggle('dropdown-mobile--open');
    collapse.toggle();
  });
});

window.addEventListener('click', (e) => {
  const target = e.target;
  const open = document.querySelector('.dropdown--open');

  if (target.closest(".dropdown > a")) {
    target.parentNode.classList.toggle('dropdown--open');
  }

  if (open) open.classList.remove('dropdown--open');
});


