import NiceSelect from '../../external/nice-select2';

const page = document.getElementById('category-page');

if (page) {
  const sideNav = page.querySelector('.content__nav');
  const nav = sideNav.querySelectorAll('.nav__list .nav__link');
  const select = page.querySelector('.content__select select');
  const nodeArray = [];

  nav.forEach(item => {
    if (item.classList.contains('current')) nodeArray.unshift(item);
    else nodeArray.push(item);
  });

  nodeArray.forEach(item => {
    const tag = document.createElement('option');
    tag.textContent = item.textContent;
    tag.dataset.link = item.href;

    select.appendChild(tag);
  });

  select.addEventListener('change', () => {
    const link = select.options[select.selectedIndex].dataset.link;
    document.location.href = link;
  });

  new NiceSelect(select);
}