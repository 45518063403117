import Swiper from "swiper";
import { Navigation, EffectFade } from 'swiper/modules';

const cartSlider = new Swiper('#cart-page .swiper', {
  modules: [Navigation, EffectFade],

  speed: 500,
  slidesPerView: 1,
  autoHeight: true,
  allowTouchMove: false,
  simulateTouch: false,

  // effect: 'fade',
  // fadeEffect: {
  //   crossFade: true,
  // },
});

const tabs = document.querySelector('.order__tabs');
if (tabs) {
  window.addEventListener('click', (e) => {
    const target = e.target;

    if (target.closest('[data-slide]')) {
      tabs.querySelector('.active').classList.remove('active');
      target.classList.add('active');

      cartSlider.slideTo(target.dataset.slide);
    }
  });
}